import React from 'react';
import { Heading, Box, Text } from 'rebass';
import { GoAlert } from 'react-icons/go';

const ErrorMessage = ({ message, description }) => {
  return (
    <Box
      bg="#FF0039"
      p={3}
      sx={{
        display: 'grid',
        gridGap: 3,
        gridTemplateColumns: 'max-content 1fr',
        gridAutoFlow: 'column',
      }}
    >
      <Box pt={1}>
        <Text fontSize={5} as={GoAlert} color="white" />
      </Box>
      <Box>
        <Heading fontFamily="body" fontWeight="bold" fontSize={2} color="white">
          {message}
        </Heading>
        <Text fontSize={1} color="white">
          {description}
        </Text>
      </Box>
    </Box>
  );
};

export default ErrorMessage;
