import theme from '@rebass/preset';

export default {
  ...theme,
  colors: {
    ...theme.colors,
    'cyan-050': '#E0FCFF',
    'cyan-500': '#2CB1BC',
    secondary: '#ffa96a',
    border: '#e6e6e6',
    background: '#F8F6F4',
    defaultText: '#272929',
    mutedText: '#62636B',
    subtleText: '#A7A7AB',
    lightText: '#ECEBEA',
    primary: '#7a69bf',
    photoGradientEnd: 'rgba(0, 0, 0, 0.4)',
  },
  fonts: {
    ...theme.fonts,
    heading: "'Zilla Slab', serif",
    body: "'DM Sans', sans-serif",
  },
  fontWeights: {
    ...theme.fontWeights,
    body: 400,
    heading: 500,
    bold: 700,
  },
  shadows: {
    small: '3px 3px 6px 0 rgba(0, 0, 0, 0.05)',
    large: '0 12px 24px 0 rgba(0, 0, 0, 0.1)',
    // taken from refactoring UI elevation with ambient light
    lowest: '0 1px 3px rgba(0,0,0,.12), 0 1px 2px rgba(0,0,0,.24)',
    low: '0 3px 6px rgba(0,0,0,.15), 0 2px 4px rgba(0,0,0,.12)',
    medium: '0 10px 20px rgba(0,0,0,.15), 0 3px 6px rgba(0,0,0,.10)',
    high: '0 15px 25px rgba(0,0,0,.15), 0 5px 10px rgba(0,0,0,.05)',
    highest: '0 20px 40px rgba(0,0,0,.2)',
  },
};
