import React, { useReducer } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import VideoRoom from './VideoRoom';
import Lobby from './Lobby';

function reducer(state, action) {
  switch (action.type) {
    case 'setRoomName':
      return { ...state, roomName: action.roomName };
    case 'setToken':
      return { ...state, token: action.token };
    case 'logout':
      return { ...state, token: null };
    default:
      return state;
  }
}

function VideoChat() {
  const { meetingId } = useParams();
  const initialState = {};
  const isStaging = !window.location.href.includes('chat.lifetales');

  const [{ roomName, token }, dispatch] = useReducer(reducer, initialState);

  if (!token)
    return (
      <Lobby
        onSubmit={async ({ identity }) => {
          const tokenResponse = await axios({
            method: 'post',
            url: `https://api${
              isStaging ? '-staging' : ''
            }.lifetales.com/api/v3/public/meetings/${meetingId}/meeting_access_tokens?identity=${identity}`,
            headers: {
              'Content-Type': 'application/json',
            },
          });
          dispatch({ type: 'setToken', token: tokenResponse.data.data.attributes['access-token'] });
        }}
      />
    );
  if (token) return <VideoRoom roomName={roomName} token={token} dispatch={dispatch} />;
}
export default VideoChat;
