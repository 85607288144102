import React from 'react';
import './App.css';
import { UserAgentProvider } from '@quentin-sommer/react-useragent';
import { BrowserRouter, Route } from 'react-router-dom';
import VideoPage from './pages/VideoPage';
import { ThemeProvider } from 'emotion-theming';
import theme from './lib/theme';

function App() {
  return (
    <UserAgentProvider ua={window.navigator.userAgent}>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <Route path="/:meetingId" component={VideoPage} />
        </BrowserRouter>
      </ThemeProvider>
    </UserAgentProvider>
  );
}

export default App;
