import React, { useRef, useEffect } from 'react';
import { Box, Button, Image } from 'rebass';
import { useState } from 'react';
import { v4 } from 'uuid';
import { useUserMedia } from '@vardius/react-user-media';
import ErrorBox from './ErrorBox';
import Spinner from 'react-spinkit';

const overlayBoxSx = {
  display: 'grid',
  alignItems: 'center',
  justifyContent: 'center',
  gridArea: '1 / 1 / 2 / 2',
  zIndex: 1,
  bg: 'rgba(0,0,0,.25)',
  boxShadow: 'inset 0 0 50px rgba(0,0,0,.7)',
};

const Preview = ({ children }) => {
  const { stream, error } = useUserMedia({ audio: true, video: true });
  const videoRef = useRef(null);
  const [showLogin, setShowLogin] = useState(false);

  useEffect(() => {
    if (videoRef) {
      if ('srcObject' in videoRef.current) {
        videoRef.current.srcObject = stream;
      } else {
        const src = URL.createObjectURL(stream);
        videoRef.current.src = src;
      }
    }
  }, [stream]);

  if (error) {
    return <ErrorBox error={error} />;
  }

  return (
    <Box sx={{ display: 'grid' }}>
      <Image
        sx={{ gridArea: '1 / 1 / 2 / 2' }}
        as="video"
        autoPlay
        ref={videoRef}
        onPlaying={() => setShowLogin(true)}
        playsInline
      />
      {!showLogin && (
        <Box sx={overlayBoxSx}>
          <Spinner name="line-scale-pulse-out" color="#999" />
        </Box>
      )}
      {showLogin && <Box sx={overlayBoxSx}>{children}</Box>}
    </Box>
  );
};

function Lobby({ onSubmit }) {
  const [identity] = useState(v4());
  const [submitting, setSubmitting] = useState(false);

  return (
    <Box p={3} sx={{ display: 'grid', gridGap: 3, justifyContent: 'center' }}>
      <Preview>
        <Button
          disabled={submitting || !identity}
          type="button"
          p={3}
          onClick={() => {
            setSubmitting(true);
            onSubmit({ identity });
          }}
        >
          Enter
        </Button>
      </Preview>
    </Box>
  );
}

export default Lobby;
