import React, { useState, useEffect } from 'react';
import { Box, Text, Button, Flex } from 'rebass';
import Video from 'twilio-video';
import ErrorBox from './ErrorBox';
import Participants from './Participants';

function VideoRoom({ roomName, token, dispatch }) {
  const [room, setRoom] = useState(null);
  const [participants, setParticipants] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const participantConnected = (participant) => setParticipants((prev) => [...prev, participant]);
    const participantDisconnected = (participant) =>
      setParticipants((prev) => prev.filter((p) => p !== participant));

    Video.connect(token, { name: roomName })
      .then((room) => {
        setRoom(room);
        room.on('participantConnected', participantConnected);
        room.on('participantDisconnected', participantDisconnected);
        room.participants.forEach(participantConnected);
      })
      .catch((err) => setError(err));

    return () => {
      setRoom((currentRoom) => {
        if (currentRoom && currentRoom.localParticipant.state === 'connected') {
          currentRoom.localParticipant.tracks.forEach(function (trackPublication) {
            trackPublication.track.stop();
          });
          currentRoom.disconnect();
          return null;
        } else {
          return currentRoom;
        }
      });
    };
  }, [roomName, token]);

  console.log(participants);

  return (
    <Box>
      <ErrorBox error={error} />
      <Participants participants={participants} localParticipant={room && room.localParticipant} />
    </Box>
  );
}

export default VideoRoom;
