import React, { useContext } from 'react';
import { Box } from 'rebass';
import { UAContext } from '@quentin-sommer/react-useragent';
import ErrorMessage from './ErrorMessage';

const useErrorDescription = (message = '') => {
  switch (message) {
    case 'Permission denied':
      return 'Grant access to audio/video devices to join chat';
    case 'getUserMedia not supported':
      return 'Use a modern browser which supports getUserMedia to join chat';
    default:
      return '';
  }
};

const ErrorBox = ({ error }) => {
  const { uaResults, parser } = useContext(UAContext);
  const errorDescription = useErrorDescription(error && error.message);

  return (
    <Box sx={{ display: 'grid', gridGap: 3, gridAutoFlow: 'rows' }}>
      {uaResults.ios && parser.getBrowser().name !== 'Mobile Safari' && (
        <ErrorMessage
          message="Must use Safari on iOS"
          description="Please connect using Safari to join chat."
        />
      )}
      {parser.getBrowser().name === 'Mobile Safari' && parser.getBrowser().major < 12 && (
        <ErrorMessage
          message="Older Safari Detected"
          description="Upgrade to at least version 13 of Safari for the best experience."
        />
      )}
      {!!error && <ErrorMessage message={error.message} description={errorDescription} />}
    </Box>
  );
};

export default ErrorBox;
