import React from 'react';
import { Heading, Box, Flex } from 'rebass';
import VideoChat from '../components/VideoChat';

function VideoPage() {
  return (
    <Flex flexDirection="column" alignItems="stretch">
      <Box bg="primary" p={3}>
        <Heading fontSize={3} color="white">
          LifeTales Video Chat
        </Heading>
      </Box>
      <VideoChat />
    </Flex>
  );
}
export default VideoPage;
